import axios from 'axios';

class ContactForm {

    constructor() {
        // Define global variables
        this.inputErrors = [];
        this.successIcon = '<svg viewBox="0 0 526.8 526.8"><path fill="none" stroke-width="40" stroke-linecap="round" stroke-miterlimit="10" d="M502.4,263.4 c0,132-107,239-239,239s-239-107-239-239s107-239,239-239"/><polyline fill="none" stroke-width="45" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points=" 149.4,230.4 243.4,334.4 492.4,88.4 "/></svg>';

        this.init();
    }


    // Class initialisation
    init() {
        const thisClass = this;

        // Check if there are form that need to use the plugin functionnalities
        const forms = document.querySelectorAll('[data-contact-form]');
        if (forms.length) {
            const thisClass = this;
            // Loop through each form in the current page
            forms.forEach(form => {

                // Prevent default form submit behavior and let axios handle this behavior
                form.addEventListener('submit', function(e) {
                    e.preventDefault();
                    thisClass.sendForm(this);
                });

                // Hide the form notification if the input has changed
                form.querySelectorAll('input, select, textarea').forEach(input => {
                    input.addEventListener('change', function() {
                        const inputName = this.getAttribute('name');
                        if (thisClass.inputErrors.includes(inputName)) {
                            const notif = thisClass.getInputNotification(form, inputName);
                            notif.style.display = 'none';
                        }
                    });
                });

            });
        }
    }


    // Send form via axios
    sendForm(form) {
        const thisClass = this;

        // Perform some actions before the form is sended
        thisClass.beforeSend(form);

        // Send the form 
        axios({
            method: 'POST',
            url: form.getAttribute('action'),
            data: new FormData(form),
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then(res => {
            console.log(res);
            const message = res.data.message;
            thisClass.requestSuccess(form, message);



        }).catch(error => {
            console.error('Erreur', error);
            const status = error.response.status;

            if (status === 422) {
                // Request throw an input error
                thisClass.inputsFailed(form, error.response.data.errors);
            } else if (status === 500) {
                // Request throw a server error
                thisClass.serverFailed(form);
            }

        });

    }


    // Perform some actions before the form is sended
    beforeSend(form) {
        const thisClass = this;

        // Refresh the recaptcha
        thisClass.captchaRefresh(form);

        // Save the send button text, change the text and disable the button
        const sendBtn = form.querySelector('[type=submit]');
        thisClass.sendBtnText = sendBtn.innerHTML;
        sendBtn.innerHTML = "Envoi en cours";
        sendBtn.disabled = true;
        sendBtn.classList.add('sending');
    }


    // Display input error
    inputsFailed(form, inputsData) {
        const thisClass = this;

        if (typeof inputsData == 'object') {
            // Loop through all the inputs data
            for (const [name, message] of Object.entries(inputsData)) {
                // Check if the notification doensn't exist yet in the DOM
                if (!thisClass.inputErrors.includes(name)) {
                    // Create a form notification
                    const notif = thisClass.createFormNotification(message, 'danger');
                    // Append the notification to the "form-group" of the input
                    const formGroup = form.querySelector(`[name="${name}"]`).closest('.form-group');
                    formGroup.append(notif);
                    // Add the name of the input inside a global input error array
                    thisClass.inputErrors.push(name);
                // The notification exist in the DOM
                } else {
                    // Show the existing notification
                    const notif = thisClass.getInputNotification(form, name);
                    notif.style.display = 'block';
                }
            }

            // Scroll to the first input error if needed
            thisClass.scrollToInput(form, Object.keys(inputsData)[0]);
        } else {
            console.error("Can't show input errors, the type of 'inputData' must be an object.");
        }
        
        thisClass.resetSendFormButton(form, thisClass.sendBtnText);
    }


    // Display an error due to bad server response
    serverFailed(form) {
        const thisClass = this;
        const sendFormGroup = form.querySelector('[type="submit"]').closest('.form-group');
        const notif = thisClass.createFormNotification("Erreur d'envoi", 'danger');
        const sendFormNotif = sendFormGroup.querySelector('.notif');

        // Remove the form notification if there is one already
        sendFormNotif != null ? sendFormNotif.remove() : null;

        sendFormGroup.prepend(notif);
        thisClass.resetSendFormButton(form, thisClass.sendBtnText);
    }


    // Perform some actions after the form has been successfully submitted
    requestSuccess(form, message = "") {
        const thisClass = this;

        // Display the confirm message
        const sendFormGroup = form.querySelector('[type="submit"]').closest('.form-group');
        const successTemplate = thisClass.getSuccessTemplate(message);
        sendFormGroup.innerHTML = successTemplate;
    }


    // Reset the send form button
    resetSendFormButton(form, buttonText) {
        const button = form.querySelector('[type="submit"]');
        button.innerHTML = buttonText;
        button.disabled = false;
        button.classList.remove('sending', 'disabled');
    }


    // Create and return a form notification element
    createFormNotification(message, type = 'infos') {
        const notif = document.createElement('span');
        notif.classList.add('notif', `notif-${type}`);
        notif.textContent = message;
        return notif;
    }


    // Scroll to a form input if needed
    scrollToInput(form, name) {
        const input = form.querySelector(`[name="${name}"]`);
        const inputPosView = input.getBoundingClientRect().top;
        const isVisible = 100;

        // Check if the input position is not considered as "visible"
        if (inputPosView < isVisible) {
            const inputPosY = input.offsetTop;
            input.scrollIntoView({block: 'center', behavior: 'smooth'});
        }
    }
    

    // Refresh the recaptcha of a given form
    captchaRefresh(form) {
        if (typeof refreshRecaptcha === 'function') {
            refreshRecaptcha($(form).find('[name="g-recaptcha-response"]').attr('id'));
        }
    }


    getInputNotification(form, name) {
        return form.querySelector(`[name="${name}"]`).closest('.form-group').querySelector('.notif');
    }


    // Get success template
    getSuccessTemplate(message) {
        const thisClass = this;
        return `
            <div class="notif-form-success">
                ${thisClass.successIcon}
                <span>${message}</span>
            </div>
        `;
    }
}
window.addEventListener('load', function() {
    let contactForm = '#contact_form';
    if ($(contactForm).length) {
        new ContactForm();
    }
});